<template>
    <nav :class="className">
        <div class="logo-container">
            <div class="logo" @click="$emit('logo-clicked')">
                <slot name="logo">
                    {{ logoText }}
                </slot>
            </div>

            <div class="mobile-icons">
                <label class="burger-menu" @click="toggleSidebar">
                    <span class="fa fa-bars"></span>
                </label>
            </div>
        </div>

        <ul :class="{'show': showMenu}" class="thin-scrollbar">
            <lego-nav-item v-bind="item" @click="showMenu = false" @itemClicked="showMenu = false"
                           v-for="(item, index) in items" :key="index"/>
        </ul>
        <div :class="{'show': showMenu}">
            <slot name="nav-footer"></slot>
        </div>
    </nav>
</template>

<script>
import LegoNavItem from './NavItem';

export default {
    name       : 'lego-side-bar',
    components : { LegoNavItem },
    props      : {
        logoText                : { type : String, default : 'Xeoscript Technologies' },
        items                   : { type : Array, required : true },
        shadow                  : { type : String, default : '5' },
        transparent             : { type : Boolean, default : false },
        colored                 : { type : Boolean, default : false },
        sticky                  : { type : Boolean, default : false },
        dropDownAnimation       : { type : String, default : 'zoom' },
        hoverEffect             : { type : String, default : 'basic-1' },
        dropDownAnimationMobile : { type : String, default : 'bounce' }
    },
    data () {
        return {
            animate  : false,
            showMenu : false
        };
    },

    mounted () {
        const that = this;
        setTimeout(function () {
            that.enableAnimations();
        }, 200);
    },

    computed : {
        className : function () {
            const names = ['side-bar'];

            if (this.dropDownAnimation) {
                // names.push(`nav-dd-${this.dropDownAnimation}`);
                names.push('nav-dd-' + this.dropDownAnimation);
                if (this.animate) {
                    names.push('nav-dd-animate');
                }
            }

            if (this.sticky) {
                names.push('nav-sticky');
            }

            if (this.dropDownAnimationMobile) {
                // names.push(`nav-mdd-${this.dropDownAnimationMobile}`);
                names.push('nav-mdd-' + this.dropDownAnimationMobile);
                if (this.animate) {
                    names.push('nav-mdd-animate');
                }
            }

            return names;
        }
    },

    methods : {
        enableAnimations () {
            this.animate = true;
        },
        toggleSidebar () {
            this.showMenu = !this.showMenu;
            this.$emit('sidebar-expanded', this.showMenu);
        }
    }
};
</script>

<style scoped>

</style>
