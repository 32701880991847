<template>
    <div class="fl-x" @click="redirectTOLogin">
        <div v-if="user.is_superuser">
            <img class="c-logo-img logo-super-admin" src="../../assets/web/homee/super-admin-logo.png" alt="">
        </div>
        <div v-else class="fl-x">
            <img class="c-logo-img" src="../../assets/web/dashboard/logos/logo-icon.png" alt="">
            <div class="ml-2 lh-unset fl-y fl-j-c">
                <p class="p-0 m-0 fs--1 sm-text lh-unset font-inter-light text-4">Bank e Auctions India.com
                </p>
                <!--            <p v-if="user.is_superuser" class="m-0 p-0 mt&#45;&#45;6p lg-text text-white fs-lg-0">Super Admin</p>-->
                <p v-if="user.is_ventura_admin" class="m-0 p-0 mt--6p lg-text text-white fs-lg-0">Admin</p>
                <p v-else-if="user.is_bank_admin" class="m-0 p-0 mt--6p lg-text text-white fs-lg-0 ">Bank Admin</p>
                <p v-else class="m-0 p-0 mt--6p lg-text font-weight-bold fs-lg-2 text-gradient">Bank User</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'LogoComponent',

    props : {
        user : {
            type : Object
        }
    },
    methods : {
        redirectTOLogin () {
            this.$router.push('/').then(() => {
                window.location.reload(true);
            });
        }
    }
};
</script>

<style scoped lang="scss">
.c-logo-img {
    //height: 2.7rem !important;
    border-radius: var(--round-2);
}

.pos-abs-r {
    position: absolute;
    right: 0;
}

.sm-text {
    font-size: 0.75rem;
    @media (min-width: 992px) {
        font-size: 0.75rem;
        /*font-size: 0.63rem;*/
    }
}

.lg-text {
    font-size: 0.9rem;
    font-weight: 800 !important;
    @media (min-width: 992px) {
        font-size: 1rem;
    }
}

.mt--6p {
    margin-top: -7px !important;
}

.text-gradient {
    //background-image: linear-gradient(to right, #e27a13, #ddba1e, #e27a13);
    background: rgb(24, 79, 192);
    background: linear-gradient(90deg, rgba(24, 79, 192, 1) 0%, rgba(15, 112, 220, 1) 63%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;
}
</style>
