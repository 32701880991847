import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon  : 'fa fa-home',
            text  : 'Home',
            link  : '#',
            items : [
                {
                    text : 'Banner',
                    link : '/cms/banner/'
                },
                {
                    text : 'Featured Banks',
                    link : '/cms/featured-banks/'
                },
                {
                    text : 'Testimonial',
                    link : '/cms/testimonial/'
                },
                {
                    text : 'Social Media',
                    link : '/cms/social-media/'
                }
            ]
        },
        {
            icon  : 'fa fa-user-secret',
            text  : 'About Us',
            link  : '#',
            items : [
                {
                    text : 'About Us',
                    link : '/cms/about-us/'
                },
                {
                    text : 'Vision & Mission',
                    link : '/cms/vision-and-mission/'
                },
                {
                    text : 'How It Works',
                    link : '/cms/how-it-works/'
                },
                {
                    text : 'Our Features',
                    link : '/cms/our-features/'
                }
            ]
        },
        {
            icon  : 'fa fa-address-book',
            text  : 'Contact Us',
            link  : '#',
            items : [
                {
                    text : 'Office',
                    link : '/cms/office/'
                },
                {
                    text : 'Directory',
                    link : '/cms/directory/'
                },
                {
                    text : 'Contact Us Banner',
                    link : '/cms/contact-us-banner/'
                }
            ]
        },
        {
            icon : 'fa fa-users',
            text : 'Our Team',
            link : '/cms/team/'
        },
        {
            icon : 'fa fa-commenting',
            text : 'FAQ',
            link : '/cms/faq/'
        },
        {
            icon  : 'fa fa-newspaper-o',
            text  : 'Media',
            link  : '#',
            items : [
                {
                    text : 'News',
                    link : '/cms/news/media/'
                },
                {
                    text : 'Blogs',
                    link : '/cms/blog/media/'
                }
            ]
        },
        {
            icon  : 'fa fa-lock',
            text  : 'Privacy and Terms',
            link  : '#',
            items : [
                {
                    text : 'Privacy & Policy',
                    link : '/cms/privacy-and-policy/'
                },
                {
                    text : 'Terms & Conditions',
                    link : '/cms/terms-and-conditions/'
                }
            ]
        }
    ];

    const adminMenu = [
        {
            text  : 'Admin Master Data',
            link  : '#',
            items : [
                {
                    text : 'Master Data',
                    link : '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
