import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            class : 'dashboard-icon',
            text  : 'Dashboard',
            link  : '/'
        },

        {
            class : 'profile-icon',
            text  : 'Profile Update',
            link  : '/profile/'
        },

        {
            class : 'update-password-icon',
            text  : 'Update Password',
            link  : '/update-password/'
        }
    ];

    if (user.is_superuser || user.is_ventura_admin || user.is_bank_admin || user.permission.can_view_bidder) {
        menu.push({
            class : 'faq-icon',
            text  : 'Bidders List',
            link  : '/bidders-list/'
        });
    }

    if (user.is_superuser || user.is_ventura_admin) {
        menu.push({
            class : 'bank-menu-icon',
            text  : 'Banks/FIs List',
            link  : '/banks-list/All/'
        });
    }

    if (user.is_bank_admin) {
        menu.push(
            {
                class : 'profile-icon',
                text  : 'Users List',
                link  : '/users-list/'
            }
        );
    }

    if (user.is_superuser || user.is_ventura_admin) {
        menu.push({
            class : 'manage-auctions-icon',
            text  : 'Manage Users',
            link  : '/manage-users/0/'
        });
    }

    menu.push({
        class : 'auctions-icon',
        text  : 'Auctions List',
        link  : '/auctions-list/All/'
    }, {
        class : 'auctions-icon',
        text  : 'Drafted Auctions List',
        link  : '/drafted-auctions-list/'
    });

    if (user.is_superuser || user.is_bank_admin || user.is_ventura_admin || user.permission.can_view_live_auction) {
        menu.push({
            class : 'live-auctions',
            text  : 'Live Auction',
            link  : '/live-auction/'
        });
    }

    if (user.is_superuser || user.permission.can_manage_site) {
        menu.push(
            {
                text  : 'Site Management',
                class : 'site-manage-icon',
                link  : '#',
                event : 'site-management',
                items : [
                    {
                        text  : 'CMS',
                        link  : '/cms/',
                        event : 'cms',
                        class : 'cms-link'
                    },
                    {
                        text  : 'Master Data',
                        link  : '/master-data/',
                        event : 'master-data',
                        class : 'master-data-link'
                    },
                    {
                        text  : 'Contact Us Enquiries',
                        link  : '/enquiries/',
                        event : 'enquiries',
                        class : 'enquiries-link'
                    }
                ]
            }
        );
    }

    if (user.is_bank_admin) {
        menu.push({
            class : 'refund-icon',
            text  : 'Refund Status',
            link  : '/refund/completed-auctions/'
        });
    }

    if (user.is_superuser || user.is_bank_admin || user.is_ventura_admin || user.permission.can_upload_refund) {
        menu.push({
            class : 'refund-icon',
            text  : 'Refund Template',
            link  : '/refund-template/'
        });
    }

    if (user.is_superuser || user.is_ventura_admin || user.is_bank_admin || user.permission.can_view_report) {
        menu.push(
            {
                class : 'faq-icon',
                text  : 'Reports',
                link  : '/reports/'
            }
        );
    }

    if (user.is_superuser || user.is_ventura_admin) {
        menu.push(
            {
                class : 'faq-icon',
                text  : 'Transaction',
                link  : '/transactions/'
            }
        );
    }

    if (user.is_superuser || user.is_ventura_admin) {
        menu.push(
            {
                class : 'faq-icon',
                text  : 'Request Data List',
                link  : '/request-data-list/'
            }
        );
        menu.push(
            {
                class : 'faq-icon',
                text  : 'Auction Enquiry',
                link  : '/enquiry-list/'
            }
        );
    }

    menu.push({
        class : 'logout-icon',
        text  : 'Logout',
        link  : '/logout/'
    });

    if (user && user.is_superuser === true) {
        return [
            ...menu
            // ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
