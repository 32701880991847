<template>
    <div class="page page-sidebar">

        <notifications class="notification-responsive"/>
        <lego-side-bar class="super-admin" :class="{'sidebar-expanded': sidebarExpanded}" @sidebar-expanded="onToggleSidebar" @link-event="linkEvent" ref="LegoSidebar"
                       drop-down-animation="zoom"
                       :items="menu"
                       logo-text="Bank e Auctions India.com" :colored="false" has-separator>

            <template #logo>
                <div class="logo-line-bottom w-100 pb-lg-4 pb-2">
                    <logo-component :user="currentUser"></logo-component>
                </div>
                <p class="fs--1 d-sm-none-lg-block text-7 mt-3 mb-1 ml-2 mb-0">MAIN MENU</p>
            </template>
            <template #nav-footer>
                <div class="nav-footer d-lg-none">
                    <super-admin-avatar-card class="text-3"
                                             v-if="currentUser.is_superuser || currentUser.is_ventura_admin"
                                             :name="currentUser.name" designation=""
                                             :img="currentUser.bank.bank_logo || avatarPlaceholder"
                                             details-path="/">

                        <template #details>
                            <div class="text-center font-weight-medium text-white">
                                <div class="mb-1 fs--1">
                                    <span>Email:</span><span v-html="currentUser.email">josephth@gmail.com</span>
                                </div>
                                <div class="mb-1 fs--1">
                                    <span>Phone:</span><span v-html="currentUser.mobile_number">+91 9376835131</span>

                                </div>
                            </div>
                        </template>

                    </super-admin-avatar-card>
                    <avatar-card class="text-3" :name="currentUser.name" designation=""
                                 :img="currentUser.bank.bank_logo || avatarPlaceholder"
                                 details-path="/" v-else>

                        <template #details>
                            <div class="text-center font-inter-medium fs-lg--1">
                                <div class="mb-1 fs--2">
                                    <span>Email: </span><span v-html="currentUser.email">minuthomas@gmail.com</span>
                                </div>
                                <div class="mb-1 fs--2">
                                    <span>Phone: </span><span v-html="currentUser.mobile_number">+91 9376835131</span>
                                </div>
                                <div class="fs--2">
                                    <span>Role: </span><span v-html="currentUser.employee_role">Assistant Manager</span>
                                </div>
                            </div>
                        </template>

                    </avatar-card>
                </div>
            </template>
        </lego-side-bar>

        <div class="nav-container" v-if="navbarVisibility">

            <lego-nav-bar drop-down-animation="fade" :colored="true" logo-text="" hover-effect="0"
                          class="headerFixed nav container text-white" shadow="0" :items="navMenu"/>

        </div>

        <div class="page-content-outer bg-1 pos-r">

            <div class="page-content page-p-t px-3" :class="{'pt-9 pt-lg-8':$router.currentRoute.name !== 'Home'}">

                <header-component class="mt-6 pt-4 pt-lg-3 mt-lg-0"
                                  :class="{'top-3':$router.currentRoute.path.includes('/cms/')}"
                                  v-if="$router.currentRoute.name !== 'Home'"></header-component>

                <!--                <header-component  class="" size="sm" :fixed-top="false"-->
                <!--                                  v-if="$router.currentRoute.name === 'Home'"></header-component>-->

                <div class="thin-scrollbar of-h" :class="{' mt--3re':!$router.currentRoute.path.includes('/cms/')}">
                    <transition name="fade"
                                enter-active-class="animated fadeIn"
                                leave-active-class="animated fadeOut" mode="out-in">
                        <router-view>

                        </router-view>
                    </transition>
                </div>

            </div>

            <div class="bg-primary-900 p-3">
                © {{ new Date().getFullYear() }} Bank e Auctions India.com
            </div>

        </div>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import LegoSideBar from '../components/lego-custom/CustomSideNavbar';
import getMenu from '../data/menu';
import getMenu1 from '../data/navMenu';
import getCmsMenu from '../data/cmsMenu';
import getMasterDataMenu from '../data/master-data-menu';
import geEnquiriesMenu from '../data/enquiries-menu';
import LogoComponent from '@components/dashboard/LogoComponent';
import HeaderComponent from '@components/HeaderComponent';
import bankLogo from '../assets/web/dashboard/logos/yes-logo.png';
import { mapGetters } from 'vuex';
import SuperAdminAvatarCard from '@components/dashboard/SuperAdminAvatarCard';
import AvatarCard from '@components/dashboard/AvatarCard';
import avatarPlaceholder from '../assets/web/homee/User-Admin.png';

export default {
    components : {
        AvatarCard,
        SuperAdminAvatarCard,
        HeaderComponent,
        LogoComponent,
        LegoNavBar,
        LegoSideBar
    },

    data () {
        return {
            menu              : getMenu(),
            menu1             : getMenu1(),
            cmsMenu           : getCmsMenu(),
            navMenu           : getCmsMenu(),
            masterDataMenu    : getMasterDataMenu(),
            enquiriesMenu     : geEnquiriesMenu(),
            value             : '',
            year              : new Date().getFullYear(),
            bankLogo          : bankLogo,
            navbarVisibility  : false,
            avatarPlaceholder : avatarPlaceholder,
            sidebarExpanded   : false
        };
    },

    mounted () {
        const that = this;
        if (this.currentUser.is_superuser || this.currentUser.permission.can_manage_site) {
            const cmsLink = document.querySelector('.cms-link');
            console.log('cms-link', cmsLink);
            cmsLink.addEventListener('click', function () {
                that.navMenu = getCmsMenu();
                that.navbarVisibility = true;
                that.$router.push({ path : '/cms/banner/' });
            });

            const masterDataLink = document.querySelector('.master-data-link');
            masterDataLink.addEventListener('click', function () {
                that.navMenu = that.masterDataMenu;
                that.navbarVisibility = true;
                that.$router.push({ path : '/cms/master-data/' });
            });

            const enquiriesLink = document.querySelector('.enquiries-link');
            enquiriesLink.addEventListener('click', function () {
                that.navMenu = that.enquiriesMenu;
                that.navbarVisibility = true;
                that.$router.push({ path : '/cms/enquiries/' });
            });

            if (this.$router.currentRoute.path.includes('/cms/')) {
                this.navbarVisibility = true;
            } else {
                this.navbarVisibility = false;
            }
        }
        // Hide Sidebar on click any menu
        const sidebarEl = this.$refs.LegoSidebar.$el;
        console.log('sidebar el', sidebarEl);
        const allLinks = sidebarEl.querySelector('ul');
        const linksCount = allLinks.children.length;
        const linkItems = allLinks.children;
        for (let i = 0; i < linksCount; i++) {
            linkItems[i].addEventListener('click', function () {
                that.sidebarExpanded = false;
            });
        }
        // Hide Sidebar on click any menu
    },

    computed : {
        ...mapGetters(['currentUser']),

        showNavbar () {
            if (this.$router.currentRoute.path) {
                console.log('path', this.$router.currentRoute.path);
                return true;
            }
            return true;
        }
    },

    watch : {
        '$route' (to, from) {
            // Put your logic here...
            console.log('route-to', to);
            if (to.path) {
                console.log('to path', to.path);
                if (to.path.includes('/cms/')) {
                    this.navbarVisibility = true;
                } else {
                    this.navbarVisibility = false;
                }
            }
        }
    },

    methods : {

        testNotification () {
            this.$notify('Successfully Added', 'Success',
                {
                    type : 'success'
                }
            );
        },

        async linkEvent (name) {
            if (name === 'cms') {
                console.log('abcd');
                console.log('Lego Sidebar', this.$refs.LegoSidebar.classList);
            }
            this.sidebarExpanded = false;
        },
        onToggleSidebar (visibility) {
            this.sidebarExpanded = visibility;
        }

    },

    metaInfo : {
        titleTemplate : '%s - Project Name',

        htmlAttrs : {
            lang : 'en',
            amp  : true
        }
    },

    name : 'MainLayout'
}
;
</script>

<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }

    .mt--3re {
        margin-top: -3rem !important;
    }

    .notification {
        /*fix for responsive issue in mobile screens
        Notification is not completely visible there*/
        @media (max-width: 768px) {
            width: 80% !important;
        }
    }
</style>
